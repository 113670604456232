@import './fonts.scss';

$top: #131515;
$bg: #FFFAFB;
$primary: #339989;
$muted: #7DE2D1;
$light: #E6EBE0;
$text: #2B2C28;

html {
}

body {
  margin: 0;
  font-family: 'Newsreader', serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 16pt;

  color: $text;
  background-color: $bg;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  line-height: 1.25;
}

a {
  color: $primary;

  &:hover {
    color: lighten($primary, 10%);
  }
}

img, iframe {
  max-width: 100%;
}

img {
  height: auto;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.aligncenter {
  margin: 0 auto;
}

pre {
  overflow-x: auto;
  margin: 4rem -2rem;
  padding: 2rem;
  background-color: #F0F0F0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  border: 1px solid #ddd;
  position: relative;
}

code {
  font-family: 'Source Code Pro', monospace;
}

.home-link {
  display: block;
  padding: 1rem 1.5rem;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  font-weight: bold;
}

.top {
  font-family: 'Roboto', sans-serif;
  background-color: $top;
  color: $light;
  border-bottom: 1rem solid $primary;

  a {
    color: #fff;
  }
}

.top-home .container {
  display: flex;
  padding: 3rem 0;
  align-items: center;

  > div {
    padding: 1.5rem;
    flex-grow: 1;
    min-width: 200px;
  }

  img{
    border-radius: 2rem;
  }
}


.content {
  background-color: $bg;
}

.posts {
  padding: 3rem 0;
  margin: 0;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;

  .post {
    border-top: 1px solid #ddd;
    padding: 2rem;
    margin: 0;

    &:first-child {
      border-top: 0;
      grid-column: span 2;
    }
  }
}

.post {
  display: block;
  padding: 1rem;

  h2 {
    margin: 0 0 1rem 0;
  }

  h2 small, h3 small {
    display: block;
    color: #777;
    font-weight: normal;
    font-size: 75%;
  }
}

.post-content {
  p {
    margin: 2rem 0;
  }

  .read-more {
    display: table-cell;
    padding-top: 2rem;
  }
}

.post-image-thumbnail {
  float: right;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.post-image-wide {
  width: 100%;
  height: 100%;
}

.post-meta {
  font-family: 'Roboto', sans-serif;
  display: block;
  margin-top: 1rem;
  font-size: 80%;
  color: #999;
}

.post-meta-links {
  float: right;
  padding: 0;
  margin: 0;
}

.post-meta-links li {
  display: inline;
  padding-left: 1rem;
}

.post-meta-links li::before {
  content: '[ ';
}

.post-meta-links li::after {
  content: ' ]'
}

.post-meta-links li a {
  text-decoration: none;
}

.pages {
  text-align: center;

  a {
    padding: 2rem;
  }
}

.comment-form textarea {
  width: 100%;
  height: 100px;
}

.comments {
  margin-bottom: 3rem;
}

.blog-comment, .comment-form {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 3rem;
  border-radius: 2px;
  margin: 2rem -3rem 0 -3rem;
}

.blog-comment p {
  margin: 0 0 1rem 0;
}

.comment-form textarea {
  border: 0;
  font-family: Georgia, serif;
  font-size: 100%;
}

.comment-author {
  margin: 0;
  display: block;
  text-align: right;

  small {
    color: #999;
  }
}

.tag-title {
  padding: 1rem 2rem;
}

@import './responsive.scss';