/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("roboto-v20-latin-300.2deec2e9.eot");
  /* IE9 Compat Modes */
  src: local(""), url("roboto-v20-latin-300.2deec2e9.eot") format("embedded-opentype"), url("roboto-v20-latin-300.b57a7f6a.woff2") format("woff2"), url("roboto-v20-latin-300.44a6720b.woff") format("woff"), url("roboto-v20-latin-300.c329a568.ttf") format("truetype"), url("roboto-v20-latin-300.3039ca1d.svg") format("svg");
  /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("roboto-v20-latin-900.884fb515.eot");
  /* IE9 Compat Modes */
  src: local(""), url("roboto-v20-latin-900.884fb515.eot") format("embedded-opentype"), url("roboto-v20-latin-900.1a456512.woff2") format("woff2"), url("roboto-v20-latin-900.7e7f95d7.woff") format("woff"), url("roboto-v20-latin-900.5d658600.ttf") format("truetype"), url("roboto-v20-latin-900.548d0fba.svg") format("svg");
  /* Legacy iOS */
}
/* roboto-900italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("roboto-v20-latin-900italic.a47064c0.eot");
  /* IE9 Compat Modes */
  src: local(""), url("roboto-v20-latin-900italic.a47064c0.eot") format("embedded-opentype"), url("roboto-v20-latin-900italic.052c55a8.woff2") format("woff2"), url("roboto-v20-latin-900italic.a39b425b.woff") format("woff"), url("roboto-v20-latin-900italic.664a91fb.ttf") format("truetype"), url("roboto-v20-latin-900italic.f9d71d46.svg") format("svg");
  /* Legacy iOS */
}
/* newsreader-regular - latin */
@font-face {
  font-family: "Newsreader";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("newsreader-v7-latin-regular.ad29f16e.eot");
  /* IE9 Compat Modes */
  src: local(""), url("newsreader-v7-latin-regular.ad29f16e.eot") format("embedded-opentype"), url("newsreader-v7-latin-regular.ab6efce7.woff2") format("woff2"), url("newsreader-v7-latin-regular.a10864e0.woff") format("woff"), url("newsreader-v7-latin-regular.87a86dfe.ttf") format("truetype"), url("newsreader-v7-latin-regular.5f925480.svg") format("svg");
  /* Legacy iOS */
}
/* newsreader-600 - latin */
@font-face {
  font-family: "Newsreader";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("newsreader-v7-latin-600.9807384f.eot");
  /* IE9 Compat Modes */
  src: local(""), url("newsreader-v7-latin-600.9807384f.eot") format("embedded-opentype"), url("newsreader-v7-latin-600.067b9d57.woff2") format("woff2"), url("newsreader-v7-latin-600.bca3dd8c.woff") format("woff"), url("newsreader-v7-latin-600.f10916c8.ttf") format("truetype"), url("newsreader-v7-latin-600.61638e87.svg") format("svg");
  /* Legacy iOS */
}
/* newsreader-italic - latin */
@font-face {
  font-family: "Newsreader";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("newsreader-v7-latin-italic.34877e9a.eot");
  /* IE9 Compat Modes */
  src: local(""), url("newsreader-v7-latin-italic.34877e9a.eot") format("embedded-opentype"), url("newsreader-v7-latin-italic.36b4743e.woff2") format("woff2"), url("newsreader-v7-latin-italic.3af754ff.woff") format("woff"), url("newsreader-v7-latin-italic.d6d7295f.ttf") format("truetype"), url("newsreader-v7-latin-italic.74021866.svg") format("svg");
  /* Legacy iOS */
}
/* source-code-pro-regular - latin */
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("source-code-pro-v13-latin-regular.e8109312.eot");
  /* IE9 Compat Modes */
  src: local(""), url("source-code-pro-v13-latin-regular.e8109312.eot") format("embedded-opentype"), url("source-code-pro-v13-latin-regular.19ece2f8.woff2") format("woff2"), url("source-code-pro-v13-latin-regular.5f2933a9.woff") format("woff"), url("source-code-pro-v13-latin-regular.27926f1a.ttf") format("truetype"), url("source-code-pro-v13-latin-regular.61d60c46.svg") format("svg");
  /* Legacy iOS */
}
body {
  margin: 0;
  font-family: "Newsreader", serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 16pt;
  color: #2B2C28;
  background-color: #FFFAFB;
  display: flex;
  flex-direction: column;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  line-height: 1.25;
}
a {
  color: #339989;
}
a:hover {
  color: #40bfab;
}
img, iframe {
  max-width: 100%;
}
img {
  height: auto;
}
.alignright {
  float: right;
}
.alignleft {
  float: left;
}
.aligncenter {
  margin: 0 auto;
}
pre {
  overflow-x: auto;
  margin: 4rem -2rem;
  padding: 2rem;
  background-color: #F0F0F0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  border: 1px solid #ddd;
  position: relative;
}
code {
  font-family: "Source Code Pro", monospace;
}
.home-link {
  display: block;
  padding: 1rem 1.5rem;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  font-weight: bold;
}
.top {
  font-family: "Roboto", sans-serif;
  background-color: #131515;
  color: #E6EBE0;
  border-bottom: 1rem solid #339989;
}
.top a {
  color: #fff;
}
.top-home .container {
  display: flex;
  padding: 3rem 0;
  align-items: center;
}
.top-home .container > div {
  padding: 1.5rem;
  flex-grow: 1;
  min-width: 200px;
}
.top-home .container img {
  border-radius: 2rem;
}
.content {
  background-color: #FFFAFB;
}
.posts {
  padding: 3rem 0;
  margin: 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.posts .post {
  border-top: 1px solid #ddd;
  padding: 2rem;
  margin: 0;
}
.posts .post:first-child {
  border-top: 0;
  grid-column: span 2;
}
.post {
  display: block;
  padding: 1rem;
}
.post h2 {
  margin: 0 0 1rem 0;
}
.post h2 small, .post h3 small {
  display: block;
  color: #777;
  font-weight: normal;
  font-size: 75%;
}
.post-content p {
  margin: 2rem 0;
}
.post-content .read-more {
  display: table-cell;
  padding-top: 2rem;
}
.post-image-thumbnail {
  float: right;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}
.post-image-wide {
  width: 100%;
  height: 100%;
}
.post-meta {
  font-family: "Roboto", sans-serif;
  display: block;
  margin-top: 1rem;
  font-size: 80%;
  color: #999;
}
.post-meta-links {
  float: right;
  padding: 0;
  margin: 0;
}
.post-meta-links li {
  display: inline;
  padding-left: 1rem;
}
.post-meta-links li::before {
  content: "[ ";
}
.post-meta-links li::after {
  content: " ]";
}
.post-meta-links li a {
  text-decoration: none;
}
.pages {
  text-align: center;
}
.pages a {
  padding: 2rem;
}
.comment-form textarea {
  width: 100%;
  height: 100px;
}
.comments {
  margin-bottom: 3rem;
}
.blog-comment, .comment-form {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 3rem;
  border-radius: 2px;
  margin: 2rem -3rem 0 -3rem;
}
.blog-comment p {
  margin: 0 0 1rem 0;
}
.comment-form textarea {
  border: 0;
  font-family: Georgia, serif;
  font-size: 100%;
}
.comment-author {
  margin: 0;
  display: block;
  text-align: right;
}
.comment-author small {
  color: #999;
}
.tag-title {
  padding: 1rem 2rem;
}
@media (max-width: 800px) {
  .top-home .container {
    padding: 0 1rem;
  }

  .top-picture {
    display: none;
  }

  .top a {
    margin: 0 0 2rem 0;
  }
  .top li:last-child a {
    margin-bottom: 0;
  }

  .post-meta-links {
    float: none;
  }

  .posts {
    display: block;
    padding: 0;
    margin: 0 1rem;
  }
  .posts .post {
    padding: 1rem;
  }

  pre {
    margin: 0 -1rem;
    padding: 0.5rem;
    border-left: 0;
    border-right: 0;
  }

  .post-meta-links li {
    padding: 0;
    display: inline;
  }
}
@media (min-width: 960px) {
  .home-link {
    border-radius: 0 0 4px 0;
    position: fixed;
  }

  .container {
    max-width: 960px;
    margin: 0 auto;
  }

  .post {
    padding: 5rem 3rem;
    margin: 0 -3rem;
  }

  .post:last-child {
    margin-bottom: 5rem;
  }
}
/*# sourceMappingURL=main.css.map */
