/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/roboto-v20-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-v20-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* newsreader-regular - latin */
@font-face {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/newsreader-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/newsreader-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/newsreader-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/newsreader-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/newsreader-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/newsreader-v7-latin-regular.svg#Newsreader') format('svg'); /* Legacy iOS */
}

/* newsreader-600 - latin */
@font-face {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/newsreader-v7-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/newsreader-v7-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/newsreader-v7-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/newsreader-v7-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/newsreader-v7-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/newsreader-v7-latin-600.svg#Newsreader') format('svg'); /* Legacy iOS */
}

/* newsreader-italic - latin */
@font-face {
  font-family: 'Newsreader';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/newsreader-v7-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/newsreader-v7-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/newsreader-v7-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/newsreader-v7-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/newsreader-v7-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/newsreader-v7-latin-italic.svg#Newsreader') format('svg'); /* Legacy iOS */
}

/* source-code-pro-regular - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/source-code-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-code-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-code-pro-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-code-pro-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-code-pro-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-code-pro-v13-latin-regular.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}
