@media (max-width: 800px) {
  .top-home {
    .container {
      padding: 0 1rem;
    }
  }
  .top-picture {
    display: none;
  }

  .top {
    a {
      margin: 0 0 2rem 0;
    }

    li:last-child a {
      margin-bottom: 0;
    }
  }

  .post-meta-links {
    float: none;
  }
  .posts {
    display: block;
    padding: 0;
    margin: 0 1rem;

    .post {
      padding: 1rem;
    }
  }

  pre {
    margin: 0 -1rem;
    padding: 0.5rem;
    border-left: 0;
    border-right: 0;
  }


  .post-meta-links li {
    padding: 0;
    display: inline;
  }
}

@media (min-width: 960px) {
  .home-link {
    border-radius: 0 0 4px 0;
    position: fixed;
  }

  .container {
    max-width: 960px;
    margin: 0 auto;
  }

  .post {
    padding: 5rem 3rem;
    margin: 0 -3rem;
  }

  .post:last-child {
    margin-bottom: 5rem;
  }
}